<!--
 * @Description: 房东选择
 * @Author: 琢磨先生
 * @Date: 2022-10-06 23:50:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-06 23:58:12
-->
<template>
    <div>
        <el-button type="primary" size="small" link>房东选择</el-button>
    </div>
    
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        created () {
            
        },
        methods: {
            
        }
    }
</script>

<style   scoped>

</style>